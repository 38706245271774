.equipe {
    .foto {
        position: relative;
        z-index: 1;
        margin-bottom: 25px;
        img {
            position: relative;
            z-index: 1;
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
        }
        &:after {
            display: block;
            content: '';
            position: absolute;
            z-index: 2;
            width: 100%;
            max-width: 505px;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            transition: all .3s ease;
            top: 0;
            @include bgMixColor();
            opacity: 0.7;
        }
    }
    .menu {
        @include breakpoint(768px, max) {
            display: none !important;
        }
    }
    .nome {
        display: flex;
        width: 100%;
        height: 200px;
        align-items: flex-end;
        margin: 55px 0 40px;
        @include breakpoint(768px, max) {
            margin: 0 0 20px;
            height: auto;
        }
    }
    h1 {
        display: flex;
        font-size: 90px;
        line-height: 73px;
        color: $rosa;
        text-transform: uppercase;
        font-family: $khula;
        font-weight: bold;
        @include breakpoint(768px, max) {
            font-size: 60px;
            line-height: 50px;
        }
    }
    .texto {
        font-size: 20px;
        line-height: 24px;
        color: $preto;
        @include breakpoint(768px, max) {
            font-size: 16px;
            line-height: 34px;
        }
        p {
            margin-bottom: 30px;
        }
    }
}
