@import url('https://fonts.googleapis.com/css?family=Khula:400,700');
//font-family: 'Khula', sans-serif;

@font-face {
    font-family: 'HouschkaLight';
    src: url($fontsSRC + 'HouschkaLight.eot');
    src: url($fontsSRC + 'HouschkaLight.woff2') format('woff2'),
         url($fontsSRC + 'HouschkaLight.woff') format('woff'),
         url($fontsSRC + 'HouschkaLight.ttf') format('truetype'),
         url($fontsSRC + 'HouschkaLight.svg#HouschkaLight') format('svg'),
         url($fontsSRC + 'HouschkaLight.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
  }