.vitrine {
    display: block;
    width: 100%;
    height: auto;
    min-height: 400px;
    padding: 80px 0;
    @include breakpoint(768px, max) {
        min-height: initial;
        display: none;
    }
    .menu {
        @include breakpoint(768px, max) {
            display: none !important;
        }
    }
}