footer {
    display: block;
    width: 100%;
    background-color: #202020;
    color: #ffffff;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    font-family: $fontDefault;
    font-weight: 300;
    padding: 10px 0;
    @include breakpoint(768px, max) {
        padding: 10px 20px;
    }
	.copyright {
        padding: 15px 0;
        @include breakpoint(768px, max) {
            text-align: center;
        }
    }
    .text-right {
        @include breakpoint(768px, max) {
            text-align: center !important;
        }
    }
    nav {
		display: inline-block;
		vertical-align: middle;
        margin-right: 40px;
        @include breakpoint(768px, max) {
            display: block;
            margin: auto auto 20px;
        }
        ul {
            margin: 0;
            padding: 0;
            li {
                list-style-type: none;
                display: inline-block;
                a {
                    color: #ffffff;
					font-size: 25px;
					margin-left: 10px;
                }
            }
        }
    }
    .dev {
		display: inline-block;
        vertical-align: middle;
        @include breakpoint(768px, max) {
            margin-bottom: 10px;
        }
    }
}
