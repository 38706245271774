.modalWhatsApp {
    position: fixed;
    left: 20px;
    bottom: 40px;
    width: 80px;
    height: 80px;
    background-color: green;
    border-radius: 50%;
    i {
        color: #ffffff;
        text-align: center;
        font-size: 50px;
        padding: 12px 18px;
    }
    &.desktop {
        @include breakpoint(768px, max) {
            display: none;
        }
    }
    &.mobile {
        display: none;
        @include breakpoint(768px, max) {
            display: block;
        }
    }
}