.metodologia {
    background-image: url($img+"metodologia/background.png");
    background-repeat: no-repeat;
    background-position: top right;
    padding: 90px 0 0;
    .container {
        @include breakpoint(768px, max) {
            padding: 0 30px;
        }
    }
    h1 {
        text-transform: uppercase;
        font-family: $khula;
        font-weight: bold;
        color: $rosa;
        font-size: 50px;
        line-height: 50px;
        margin: 0;
        padding: 0;
        @include breakpoint(768px, max) {
            font-size: 30px;
            line-height: 32px;
        }
    }
    .subtitulo {
        color: $rosa;
        font-family: $khula;
        font-weight: bold;
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 40px;
        text-transform: uppercase;
    }
    .pr50 {
        @include breakpoint(768px, max) {
            padding-right: 0;
        }
    }
    .texto {
        font-family: $khula;
        color: $preto;
        font-size: 24px;
        line-height: 30px;
        @include breakpoint(768px, max) {
            font-size: 16px;
            line-height: 34px;
        }
    }
    .backRosa {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            z-index: 1;
            background-color: $rosa;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            transform: translateX(100%);
            background-color: $rosa;
            top: 0;
            right: 0;
            @include breakpoint(768px, max) {
                display: none;
            }
        }
    }
    .preceitos {
        @include breakpoint(768px, max) {
            padding: 0;
        }
        .conteudo {
            position: relative;
            z-index: 2;
            padding: 70px 0 70px 50px;
            @include breakpoint(768px, max) {
                padding: 30px;
            }
            h2 {
                font-family: $khula;
                font-weight: bold;
                font-size: 70px;
                line-height: 80px;
                color: #ffffff;
                padding: 0;
                margin: 0;
                @include breakpoint(768px, max) {
                    font-size: 40px;
                }
            }
            .texto {
                font-family: $houska;
                font-weight: 300;
                color: #ffffff;
                @include breakpoint(768px, max) {
                    font-size: 16px;
                    line-height: 34px;
                }
            }
        }
    }
}
