.menu {
    text-align: right;
    .botaoResponsivo {
        width: 100px;
        height: 50px;
        position: absolute;
        right: 0px;
        display: none;
        margin-top: 0;
        top: -7px;
        @include breakpoint(768px, max) {
            display: inline-block;
        }
        @include breakpoint('320px', 'max') {
            width: 75px;
        }
        .nomemr {
            float: left;
            color: $corBarraResponsivo;
            font-size: 14px;
            text-transform: uppercase;
            margin-top: 15px;
            margin-right: 10px;
            @include breakpoint('320px', 'max') {
                font-size: 10px;
            }
        }
        .linhasmr {
            float: right;
            margin-top: 8px;
            .b1 {
                top: 11px;
            }
            .b2 {
                top: 20px;
            }
            .b3 {
                top: 29px;
            }
            >span {
                background-color: $corBarraResponsivo;
                height: 4px;
                right: 0;
                position: absolute;
                top: 0;
                transition: all 0.3s ease 0s;
                width: 40px;
            }
        }
        &.ativo {
            .b1 {
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                top: 21px;
                right: 0;
            }
            .b2 {
                width: 0px;
            }
            .b3 {
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                top: 21px;
                right: 0;
            }
        }
    }
    nav {
        display: inline-block;
        transition: all .4s ease-in-out;
        @include breakpoint(768px, max) {
            width: 100vw;
            display: block;
            position: absolute;
            z-index: 999;
            background-color: $rosa;
            right: -1000px;
            top: 50px;
            height: auto;
        }
        ul {
            @include breakpoint(768px, max) {
                padding: 30px;
                margin: 0;
                
                display: block;
                overflow: auto;
            }
            li {
                list-style-type: none;
                a {
                    display: block;
                    width: 100%;
                    max-width: 155px;
                    color: $rosa;
                    font-family: $houska;
                    font-size: 20px;
                    line-height: 22px;
                    text-align: right;
                    text-transform: uppercase; 
                    margin-bottom: 10px;
                    @include breakpoint(768px, max) {
                        display: block;
                        max-width: initial;
                        color: #ffffff;
                    }
                    &:hover {
                        text-decoration: none;
                        color: $preto;
                    }
                }
                &.links {
                    display: none;
                    @include breakpoint(768px, max) {
                        display: block;
                    }
                }
                &.login {
                    display: none;
                    @include breakpoint(768px, max) {
                        display: block;
                        border-top: #fff 1px solid;
                        padding: 20px 0 30px;
                    }
                    form {
                        .col-12 {
                            margin-bottom: 8px;
                            .form-control {
                                height: 40px;
                            }
                            button {
                                display: block;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        &.ativo {
            right: initial;
            left: -20px;
        }
    }
}