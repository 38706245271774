.agenda {
    position: relative;
    padding: 0;
    min-height: 60vh;
    @include breakpoint(768px, max) {       
        min-height: initial;
        padding: 50px 0;
        margin-bottom: 40px;
    }
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-image: url($img+"agenda/background.png");
        background-repeat: no-repeat;
        background-position: top left;
        top: 0;
        left: 0;
        @include breakpoint(768px, max) {
            display: none;
        }
    }
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        width: 50%;
        height: calc(100% + 80px);
        background-color: $rosa;
        right: 0;
        top: 0;
        @include breakpoint(768px, max) {
            width: 100%;
        }
    }
    .conteudo {
        position: relative;
        z-index: 2;
        padding: 100px 0;
        @include breakpoint(768px, max) {
            padding: 0 15px;
        }
        h1 {
            display: block;
            text-transform: uppercase;
            font-family: $khula;
            font-weight: bold;
            color: #fff;
            font-size: 100px;
            line-height: 60px;
            text-align: right;
            margin: 0;
            padding: 0;
            @include breakpoint(768px, max) {
                line-height: normal;
                font-size: 60px;
            }
        }
        .subtitulo {
            color: #fff;
            font-family: $houska;
            text-align: right;
            font-weight: bold;
            font-size: 26px;
            line-height: 30px;
            margin-bottom: 40px;
        }
        .eventos {
            padding-left: 50px;
            color: #ffffff;
            font-size: 24px;
            line-height: 28px;
            word-break: break-all;
            @include breakpoint(768px, max) {
                padding-left: 0;
            }
            .evento {
                padding-bottom: 15px;
                border-bottom: #FFF 2px solid;
                margin-bottom: 15px;
                img {
                    margin-bottom: 10px;
                }
            }
            a {
                display: block;
                margin-bottom: 20px;
                img {
                    margin-bottom: 20px;
                }
                h2 {
                    color: #ffffff;
                    margin: 0;
                    font-size: 18px;
                    text-align: center;
                    word-break: break-word;
                    margin-bottom: 10px;
                }
                .dataEvento {
                    color: #ffffff;
                    font-size: 14px;
                    text-align: center;
                    word-break: break-word;
                    line-height: 14px;
                }
                .horario {
                    color: #ffffff;
                    font-size: 14px;
                    text-align: center;
                    line-height: 14px;
                    margin-bottom: 10px;
                }
                &:hover {
                    text-decoration: none;
                    h2, .dataEvento, .horario {
                        color: $cinza;
                    }
                }
            }
        }
    }
}
