.produtos {
    position: relative;
    padding: 0;
    @include breakpoint(768px, max) {       
        padding: 50px 0;
    }
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-image: url($img+"quemSomos/background.png");
        background-repeat: no-repeat;
        background-position: top left;
        top: -100px;
        left: 50px;
        @include breakpoint(768px, max) {
            display: none;
        }
    }
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        width: 50%;
        height: calc(100% + 80px);
        background-color: $rosa;
        right: 0;
        top: 0;
        @include breakpoint(768px, max) {
            display: none;
        }
    }
    .container {
        @include breakpoint(768px, max) {
            padding: 0 30px;
        }
    }
    h1 {
        text-transform: uppercase;
        font-family: $khula;
        font-weight: bold;
        color: $rosa;
        font-size: 100px;
        line-height: normal;
        margin: 0;
        padding: 0;
        @include breakpoint(768px, max) {
            font-size: 50px;
        }
    }
    .containerFotos {
        max-width: 990px;
        margin: auto auto 80px;
        @include breakpoint(768px, max) {
            margin: 0;
        }
        a {
            .foto {
                position: relative;
                margin-bottom: 25px;
                img {
                    position: relative;
                    z-index: 1;
                    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                    filter: grayscale(100%);
                }
                &:after {
                    display: none;
                    content: '';
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    transition: all .3s ease;
                    top: 0;
                    @include bgMixColor();
                    opacity: 0.5;
                }
            }
            &:hover {
                .foto {
                    &:after {
                        display: block;
                    }
                }
            }
        }
    }
}
