header {
    padding: 40px 0;
    @include breakpoint(768px, max) {
        padding: 20px;
    }
    .container {
        .links {
            @include breakpoint(768px, max) {
                display: none;
            }
            nav{
                ul {
                    display: table;
                    margin: 0;
                    padding: 0;
                    li {
                        list-style-type: none;
                        @include breakpoint(768px, max) {
                            display: inline-block;
                        }
                        a {
                            display: block;
                            color: $rosa;
                            font-family: $khula;
                            font-size: 42px;
                            line-height: 42px;
                            text-transform: uppercase;
                            font-weight: bold;
                            @include breakpoint(768px, max) {
                                font-size: 20px;
                                margin-right: 15px;
                            }
                            &:hover {
                                text-decoration: none;
                                color: $preto;
                            }
                        }
                    }
                }
            }
        }
        .logo {
            a {
                display: none;
                @include breakpoint(768px, max) {
                    display: block;
                    margin: 20px 0;
                }
                &.show {
                    display: block;
                }
            }
        }
        .login {
            @include breakpoint(768px, max) {
                display: none;
            }
            .col {
                padding: 0 5px;
            }
            form {
                .form-control {
                    height: 30px;
                }
            }
            h1 {
                font-size: 24px;
                text-align: right;
            }
            a {
                display: inline-block;
            }
        }
        .menuMini{
            margin-top: 8px;
            text-align: right;
            margin-right: 6px;
            a {
                margin-left: 10px;
                &:hover {
                    text-decoration: none;
                    color: #000000;
                }
            }
        }
        .menu {
            display: none;
            @include breakpoint(768px, max) {
                display: block;
            }
        }
    }
}