.btn {
    &.btn-new {
        font-family: $houska;
        color: #ffffff;
        font-size: 20px;
        line-height: 18px;
        text-align: center;
        border: none;
        border-radius: 0;
        @include bgMixColor();
    }
}
