.downloads {
    .form-control {
        background-image: url($img + 'downloads/lupa.png');
        background-repeat: no-repeat;
        background-position: 96% center;
        padding: 30px 60px 30px 20px;
        border-color: #333;
    }
    .gradeDownload {
        border: #212121 1px solid;
        padding: 30px;
        margin-bottom: 50px;
        h1 {
            color: $rosa;
            margin-bottom: 40px;
        }
        a {
            color: #212121;
            font-size: 24px;
            &:hover {
                text-decoration: none;
                color: $vermelho;
            }
        }
        .table th, .table td {
            border: 0;
        }
    }
}