/*
  SASS Variables for project
 */
$version: "v1.0.0";
$author: "Alexander Moraes";

// COLORS DEFAULT
$valid: #2ecc71;
$error: #e74c3c;

$vermelho: #a72324;
$vermelhoHover: #952021;
$salmao: #eaa6a7;

$rosa: #c0266a;
$preto: #3f3b40;
$cinza: #afabab;

//Tamanho Container
$containerWidth: 1240px;

//Font Padrão do Site
$fontDefault: 'HouschkaLight';
$houska: 'HouschkaLight';
$khula: 'Khula', sans-serif;

$corBarraResponsivo: #c0266a;

// PATHS
$fontsSRC: "assets/fonts/";
$img: "assets/imgs/";


// BREAKPOINTS
$breakpoints: (max: (xs: 767px), min: (sm: 768px, md: 992px, lg: 1200px));