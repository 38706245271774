.quemSomos {
    position: relative;
    padding: 0;
    @include breakpoint(768px, max) {
        background-color: $rosa;
        padding: 40px 15px;
    }
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-image: url($img+"quemSomos/background.png");
        background-repeat: no-repeat;
        background-position: top left;
        top: 0;
        left: 50px;
        @include breakpoint(768px, max) {
            display: none;
        }
    }
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        width: 50%;
        height: 50%;
        background-color: $rosa;
        right: 0;
        top: 0;
        @include breakpoint(768px, max) {
            display: none;
        }
    }
    .conteudo {
        position: relative;
        z-index: 2;
        padding: 0 0 100px;
        @include breakpoint(768px, max) {
            padding: 0;
        }
        h1 {
            text-transform: uppercase;
            font-family: $khula;
            font-weight: bold;
            text-align: left;
            color: #FFF;
            font-size: 100px;
            line-height: 80px;
            margin: 0 0 30px;
            padding: 0;
            @include breakpoint(768px, max) {
                font-size: 60px;
                line-height: 60px;
            }
        }
        .containerFotos {
            display: table;
            max-width: 1040px;
            margin: auto;
            @include breakpoint(768px, max) {
                max-width: 100%;
            }
            a {
                display: block;
                position: relative;
                z-index: 1;
                .foto {
                    position: relative;
                    z-index: 1;
                    margin-bottom: 25px;
                    img {
                        position: relative;
                        z-index: 1;
                        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                        filter: grayscale(100%);
                    }
                    &:after {
                        display: none;
                        content: '';
                        position: absolute;
                        z-index: 2;
                        width: 100%;
                        height: 100%;
                        transition: all .3s ease;
                        top: 0;
                        @include bgMixColor();
                        opacity: 0.7;
                    }
                }
                h2 {
                    position: absolute;
                    display: block;
                    z-index: 2;
                    bottom: 0;
                    color: $rosa;
                    text-align: center;
                    font-family: $khula;
                    font-size: 62px;
                    line-height: 50px;
                    text-transform: uppercase;
                    width: 100%;
                    padding: 20px 10px;
                    font-weight: bold;
                    @include breakpoint(768px, max) {
                        font-size: 40px;
                        line-height: 35px;
                    }
                }
                &:hover {
                    .foto {
                        &:after {
                            display: block;
                        }
                    }
                    h2 {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}
