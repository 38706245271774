.video {
    display: block;
    width: 100%;
    background-image: url($img + 'geral/linhas.png');
    background-position: center center;
    background-repeat: no-repeat;
    @include breakpoint(768px, max) {
        min-height: 30vh;
        margin-top: 50px;
    }
    .container {
        max-width: 900px;
    }
}