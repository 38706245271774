.produto {
    position: relative;
    padding: 0;
    .container {
        @include breakpoint(768px, max) {
            padding: 0 30px;
        }
    }
    h1 {
        text-transform: uppercase;
        font-family: $khula;
        font-weight: bold;
        color: $rosa;
        font-size: 100px;
        line-height: 100px;
        margin: 0;
        padding: 0;
        @include breakpoint(768px, max) {
            font-size: 50px;
            line-height: normal;
        }
    }
    .menu {
        @include breakpoint(768px, max) {
            display: none !important;
        }
    }
    .texto {
        display: block;
        margin: 50px 0;
        font-family: $houska;
        font-weight: 300;
        font-size: 24px;
        line-height: 30px;
        color: $preto;
        column-count: 2;
        column-gap: 80px;
        @include breakpoint(768px, max) {
            column-count: 1;
            margin: 0 0 30px;
        }
    }

    .containerFotos {
        max-width: 990px;
        margin: auto auto 80px;
        @include breakpoint(768px, max) {
            margin: 0;
        }
        a {
            .foto {
                position: relative;
                margin-bottom: 25px;
                img {
                    position: relative;
                    z-index: 1;
                    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                    filter: grayscale(100%);
                }
                &:after {
                    display: none;
                    content: "";
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    transition: all 0.3s ease;
                    top: 0;
                    @include bgMixColor();
                    opacity: 0.5;
                }
            }
            &:hover {
                .foto {
                    &:after {
                        display: block;
                    }
                }
            }
        }
    }
    .infos {
        position: relative;
        background-color: $rosa;
        padding: 60px 0 40px 90px;
        @include breakpoint(768px, max) {
            padding: 50px 30px;
        }
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            transform: translateX(100%);
            background-color: $rosa;
            top: 0;
            right: 0;
            @include breakpoint(768px, max) {
                display: none;
            }
        }
        h2 {
            color: #ffffff;
            font-size: 70px;
            line-height: 50px;
            text-transform: uppercase;
            font-family: $khula;
            font-weight: bold;
            margin-bottom: 30px;
            @include breakpoint(768px, max) {
                font-size: 50px;
            }
        }
        .texto {
            margin-top: 0;
            column-count: 1;
            color: #fff;
            font-family: $khula;
            font-weight: 400;
            @include breakpoint(768px, max) {
                font-size: 20px;
            }
        }
    }
}
