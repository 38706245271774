.manifesto {
    background-image: url($img + 'manifesto/back-left.png');
    background-repeat: no-repeat;
    background-position: center left;
    padding: 90px 0;
    .container {
        @include breakpoint(768px, max) {
            padding: 0 30px;
        }
    }
    h1 {
        text-transform: uppercase;
        font-family: $khula;
        font-weight: bold;
        color: $rosa;
        font-size: 90px;
        line-height: 65px;
        margin: 0;
        padding: 0;
    }
    h2 {
        color: $cinza;
        font-family: $khula;
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 40px;
        @include breakpoint(768px, max) {
            font-size: 28px;
        }
    }
    .texto {
        font-family: $khula;
        color: $preto;
        font-size: 16px;
        line-height: 34px;
    }
}