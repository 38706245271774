/*!
Theme Name: NewMe
Theme URI: http://www.agenciadominio.com.br/
Description: Site desenvolvido especificamente para uso da NewMe;
Author: Ag&ecirc;ncia Dom&iacute;nio
Author URI: http://www.agenciadominio.com.br/
Version: 1.0
*/

// Imports
@import "mixins/mixins";
@import "commom/imports";
@import "commom/variables";
@import "commom/fonts";
@import "default";
@import "commom/padroes";
@import "commom/loader";

//Components
@import "components/buttons";
@import "components/inputs";
@import "components/whatsapp";

//Layout Site
@import "header";
@import "menu";
@import "vitrine";
@import "video";
@import "manifesto";
@import "metodologia";
@import "produtos";
@import "produto";
@import "agenda";
@import "contato";
@import "quemSomos";
@import "equipe";
@import "downloads";
@import "cadastro";





@import "footer";