.contato {
    position: relative;
    padding: 0;
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        width: 50%;
        height: 100%;
        background-color: $rosa;
        right: 0;
        top: 0;
        @include breakpoint(768px, max) {
            display: none;
        }
    }
    .conteudo {
        position: relative;
        z-index: 2;
        padding: 100px 0;
        @include breakpoint(768px, max) {
            padding: 100px 15px;
        }
        h1 {
            text-transform: uppercase;
            font-family: $khula;
            font-weight: bold;
            color: $rosa;
            font-size: 100px;
            line-height: 60px;
            margin: 0 0 30px;
            padding: 0;
            @include breakpoint(768px, max) {
                font-size: 60px;
                line-height: normal;
                margin: 0;
            }
        }
        .mapa {
            font-size: 20px;
            @include breakpoint(768px, max) {
                padding: 0;
                margin-bottom: 40px;
            }
            h2 {
                text-transform: uppercase;
                font-weight: bold;
                font-family: $khula;
            }
            iframe {
            }
        }
        .dados {
            font-size: 20px;
            line-height: 28px;
            font-family: $houska;
            font-weight: bold;
            text-transform: uppercase;
            margin: 20px 0 40px;
            @include breakpoint(768px, max) {
                margin: 40px 0;
                padding: 0;
                font-size: 16px;
            }
            div {
                margin-bottom: 3px;
                img {
                    margin-right: 4px;
                }
            }
            a {
                color: #000;
                &:hover {
                    color: $rosa;
                    text-decoration: none;
                }
            }
        }
        .formulario {
            padding-left: 100px;
            @include breakpoint(768px, max) {
                padding: 0;
            }
            h2 {
                font-family: $khula;
                text-transform: uppercase;
            }
            form {
                .form-group {
                    margin-bottom: 5px;
                    .form-control {
                        border-radius: 8px;
                        font-weight: 300;
                        padding: 10px 15px;
                        height: auto;
                        color: $preto;
                        &::-webkit-input-placeholder {
                            /* Chrome/Opera/Safari */
                            color: $preto;
                            text-transform: uppercase;
                            font-family: $khula;
                            font-weight: 300;
                        }
                        &::-moz-placeholder {
                            /* Firefox 19+ */
                            color: $preto;
                            text-transform: uppercase;
                            font-family: $khula;
                            font-weight: 300;
                        }
                        &:-ms-input-placeholder {
                            /* IE 10+ */
                            color: $preto;
                            text-transform: uppercase;
                            font-family: $khula;
                            font-weight: 300;
                        }
                        &:-moz-placeholder {
                            /* Firefox 18- */
                            color: $preto;
                            text-transform: uppercase;
                            font-family: $khula;
                            font-weight: 300;
                        }
                    }
                    button {
                        border-radius: 8px;
                        border: none;
                        padding: 10px 10px;
                        box-shadow: none;
                        color: $preto;
                        font-family: $khula;
                        line-height: 16px;
                        font-size: 16px;
                        background-color: #ffffff;
                        text-transform: uppercase;
                        @include breakpoint(768px, max) {
                            border: $rosa 1px solid;
                        }
                    }
                }
            }
        }
    }
}
